&-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style-type: none;

  @include media(">=desktop") {
    flex-direction: row;
    align-items: center;
  }

  > li {
    position: relative;
    > a {
      @include animate(color);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(map-get($baseColors, "light"), 0.1);
      text-decoration: none;

      &:hover {
        color: map-get($baseColors, "primary");
      }
    }

    .location-logo {
      width: 100px;
      height: 100px;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 1;
      margin: 0 auto;
      @include media(">=widescreen") {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 235px;
        height: 224px;
        transform: translate(-50%,-50%);
      }
    }
  }
}
