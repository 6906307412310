&-lg {
  font-size: 15px;
  padding: 8px 23px;
  @include media(">=desktop") {
    letter-spacing: 1.2px;
    padding-left: 4.25rem;
    padding-right: 4.25rem;
  }
  @include media(">=widescreen") {
    font-size: 20px;
    padding-top: 14px;
    padding-bottom: 14px;
  }
}

&-xl {
  font-size: 15px;
  padding: 10.5px 36.5px;
  @include media(">=desktop") {
    font-size: 27.45px;
    padding: 18.5px 49.5px;
  }
}
