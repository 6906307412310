.radio-list {
  margin-bottom: 15px;
  @include media(">=widescreen") {
    margin-bottom: 34px;
  }

  &-label {
    cursor: pointer;

    input[type="radio"] {
      position: absolute;
      top: -999px;
      left: -999px;
      opacity: 0;
      visibility: hidden;

      &:checked + .radio-list-item {
        background: map-get($radioList, "active-background");
        color: map-get($radioList, "active-color");
      }
    }
  }

  &-item {
    font-size: 14px;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;

    @include media(">=tablet") {
      font-size: 18px;
      width: 32px;
      height: 32px;
    }

    &:hover {
      background: map-get($radioList, "active-background");
      color: map-get($radioList, "active-color");
    }
  }
}
