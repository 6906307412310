@font-face {
  font-family: "icomoon";
  src: url("../fonts/iconmoon/icomoon.eot?ptubta");
  src: url("../fonts/iconmoon/icomoon.eot?ptubta#iefix")
      format("embedded-opentype"),
    url("../fonts/iconmoon/icomoon.ttf?ptubta") format("truetype"),
    url("../fonts/iconmoon/icomoon.woff?ptubta") format("woff"),
    url("../fonts/iconmoon/icomoon.svg?ptubta#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-thin-right:before {
  content: "\e900";
}
.icon-tripadvisor:before {
  content: "\e901";
}
.icon-location:before {
  content: "\e902";
}
.icon-calendar:before {
  content: "\e903";
}
.icon-credit-card:before {
  content: "\e904";
}
.icon-close:before {
  content: "\e905";
}
.icon-phone:before {
  content: "\e906";
}
.icon-person:before {
  content: "\e907";
}
.icon-door-key:before {
  content: "\e908";
}
.icon-instagram:before {
  content: "\e909";
}
.icon-twitter:before {
  content: "\e90a";
}
.icon-facebook:before {
  content: "\e90b";
}
.icon-arrow-right:before {
  content: "\e90c";
}
.icon-arrow-left:before {
  content: "\e90d";
}
.icon-chevron-left:before {
  content: "\e90e";
}
.icon-chevron-right:before {
  content: "\e90f";
}
.icon-email:before {
  content: "\e910";
}
.icon-clock:before {
  content: "\e911";
}
.icon-lock:before {
  content: "\e912";
}
.icon-toggler-bg:before {
  content: "\e913";
}
.icon-check:before {
  content: "\e914";
}
