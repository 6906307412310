.socials-list {
  @extend %listreset;
  display: flex;
  font-size: 0;
  color: map-get($socialsList, "color");

  .navbar-nav & {
    padding: 25px 35px;
    margin: 30px 0 0;
  }

  > li {
    &:not(:last-child) {
      margin-right: 45px;

      .navbar-nav & {
        margin-right: 30px;
      }
    }

    &:hover,
    &.active {
      > a {
        color: map-get($socialsList, "active-color");
      }
    }

    > a {
      @include animate(color);
      text-decoration: none;
      color: currentColor;
    }
  }

  .icon {
    font-size: 25px;
  }
}
