&-heading {
  padding-bottom: 15px;
  @include media(">=tablet") {
    padding-bottom: 35px;
  }
  @include media(">=desktop") {
    padding-bottom: 55px;
  }

  h2 {
    &:only-child {
      margin-bottom: 0;
    }
  }
}
