// Global variables

$fonts: (
  "sans-serif": unquote("'Lato', sans-serfi"),
  "sans-serif-secondary": unquote("'Montserrat', sans-serfi")
);

$colors: (
  "white": #fff,
  "gray-100": #ccc,
  "gray-200": #333,
  "gray-300": #54575a,
  "gray-400": #f9f8f6,
  "gray-500": #5a5a5a,
  "gray-600": #ededed,
  "gray-700": #a3a3a3,
  "gray-800": #404040,
  "gray-900": #323232,
  "gray-1000": #67686b,
  "gray-1100": #d1d1d1,
  "gray-1200": #202020,
  "gray-1300": #989898,
  "gray-1400": #ebebeb,
  "gray-1500": #7f7f7f,
  "gray": #171717,
  "black": #000,
  "yellow": #ffce00,
  "yellow-100": #ffbc00,
  "red": #bd2130
);

$base: (
  "placeholder": map-get($colors, "gray-100")
);

$baseColors: (
  "light": map-get($colors, "white"),
  "primary": map-get($colors, "yellow"),
  "dark": map-get($colors, "black"),
  "danger": map-get($colors, "red")
);

// Breakpoints for include media mixin
$breakpoints: (
  "phone": 480px,
  "tablet": 768px,
  "desktop": 992px,
  "widescreen": 1200px,
  "ultrawide": 1440px,
  "largedesktop": 1920px,
);

// Keywords for include media mixin
$media-expressions: (
  "screen": "screen",
  "print": "print",
  "handheld": "handheld",
  "landscape": "(orientation: landscape)",
  "portrait": "(orientation: portrait)",
  "retina2x": "(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)",
  "retina3x": "(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)"
);
