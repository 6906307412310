.complexity {
  @extend %listreset;
  display: inline-flex;
  align-items: center;
  color: map-get($complexity, "color");

  > li {
    &.active {
      color: map-get($complexity, "active-color");
    }

    &:not(:last-child) {
      margin-right: -2px;
    }
  }

  .icon {
    display: inline-block;
    transform: rotate(-45deg);
    font-size: 20.5px;
  }

  &-sm {
    > li {
      &:not(:last-child) {
        margin-right: 0;
      }
    }

    .icon {
      font-size: 10px;
      @include media ('>=tablet') {
        font-size: 12px;
      }
    }
  }
}
