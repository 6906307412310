.navbar {
  font-family: map-get($navbar, "font-family");
  font-size: 16px;

  @include media(">=desktop") {
    border-bottom: 2px solid map-get($navbar, "border-color");
    box-shadow: 0 0 16px map-get($navbar, "shadow-color");
  }

  &-light {
    @include media(">=desktop") {
      background: map-deep-get($navbar, "light", "background");
    }
  }

  &-brand {
    max-width: 150px;
    margin-left: -8px;
    @include media(">=widescreen") {
      max-width: 288px;
      width: 100%;
    }
  }

  &-nav {
    background: map-get($navbar, "background");
    box-shadow: 0 10px 16px -5px rgba(map-get($baseColors, "dark"), 0.5);

    @include media(">=desktop") {
      box-shadow: none;
    }

    &.ml-auto {
      @include media(">=widescreen") {
        margin-right: -20px;
      }
    }

    .nav-item {
      @include media(">=desktop") {
        margin-right: -1px;
      }
    }

    .nav-link {
      display: block;
      font-size: 20px;
      letter-spacing: -0.42px;
      font-weight: 500;
      text-decoration: none;
      border-left: 1px solid map-deep-get($navbar, "link", "border-color");
      border-right: 1px solid map-deep-get($navbar, "link", "border-color");
      border-bottom: 1px solid map-get($navbar, "border-color-xs");
      padding: 14px 30px;
      white-space: nowrap;
      @include media(">=desktop") {
        font-size: 16px;
        padding: 20px 10px;
        border-bottom: none;
      }
      @include media(">=widescreen") {
        padding-top: 32px;
        padding-bottom: 32px;
      }
    }
  }

  &-expand-lg .navbar-nav .nav-link {
    @include media(">=desktop") {
      padding-left: 15px;
      padding-right: 15px;
    }
    @include media(">=1440px") {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  .container {
    max-width: 100%;
    @include media(">=widescreen") {
      max-width: 1250px;
    }
  }

  @import "navbar-toggler";
  @import "navbar-content";
  @import "navbar-collapse";
}
