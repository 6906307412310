&-toggler {
  font-size: 0;
  display: inline-block;

  &,
  &:focus {
    outline: none;
  }

  &-icon.navbar-toggler-icon {
    background: none;
    width: auto;
    height: auto;

    .icon-toggler {
      @extend .icon-toggler-bg;
      font-size: 16px;
    }
  }
}
