&-info {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-family: map-deep-get($questCard, "info", "font-family");
  background: map-deep-get($questCard, "info", "background");
  padding: 55px;
  font-size: 16px;
  padding: 15px 25px;

  @include media ('>=desktop') {
    padding: 25px 30px;
  }

  .quest-card-title {
    font-size: 19px;

    @include media ('>=desktop') {
      font-size: 24px;
      margin: 0 0 10px;
    }
  }

  .btn-lg {
    font-size: 13px;
    padding: 10px 19px;
    @include media ('>=widescreen') {
      font-size: 20px;
      padding: 14px 32px;
    }
  }

  .complexity {
    margin-right: 10px;
    @include media ('>=desktop') {
      margin-right: 25px;
    }
  }
}
