.contact-form {
  margin-bottom: 20px;

  @include media(">=desktop") {
    max-width: 390px;
  }

  .form-group {
    margin-bottom: 20px;

    &:last-of-type {
      @include media(">=desktop") {
        margin-bottom: 60px;
      }
    }
  }

  .icon {
    font-size: 19px;
  }

  .icon-person {
    font-size: 21px;
  }

  textarea.form-control {
    border: none;
    resize: none;
    background: none;
    border-bottom: 1px solid map-get($contactForm, "border-color");
  }

  .input-group-prepend {
    margin: 0;
  }

  input[type="submit"] {
    display: block;
    width: 100%;
    @include media(">=desktop") {
      display: inline-block;
      width: auto;
    }
  }
}
