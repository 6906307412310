.section-book {
  position: relative;
  color: #dadada;
  z-index: 1;
  padding: 0;
  margin: -44px 0 -53px;
  @include media(">=widescreen") {
    margin: -375px 0 -380px;
  }

  .section-body {
    background: #323232;
    padding: 15px 0 25px;

    @include media(">=tablet") {
      padding: 50px 0;
    }

    @include media ('>=widescreen') {
      padding: 50px 0 140px;
    }
  }

  &-top {
    position: relative;
    height: 45px;

    @include media(">=widescreen") {
      height: 300px;
    }

    .section-graph-left,
    .section-graph-right {
      position: absolute;
      bottom: 0;
    }

    .section-graph-left {
      @include triangle(26vw 45px, #323232, down-left);
      left: 0;
      @include media(">=widescreen") {
        @include triangle(44vw 280px, #323232, down-left);
      }
    }

    .section-graph-right {
      @include triangle(74vw 46px, #323232, down-right);
      right: 0;
      @include media(">=widescreen") {
        @include triangle(57vw 289px, #323232, down-right);
      }
    }
  }

  &-bottom {
    position: relative;
    height: 53px;

    @include media(">=widescreen") {
      height: 300px;
    }

    .section-graph-left,
    .section-graph-right {
      position: absolute;
      top: 0;
    }

    .section-graph-left {
      @include triangle(29vw 45px, #323232, up-left);
      left: 0;
      @include media(">=widescreen") {
        @include triangle(43.5vw 270px, #323232, up-left);
      }
    }

    .section-graph-right {
      @include triangle(71vw 53px, #323232, up-right);
      right: 0;
      @include media(">=widescreen") {
        @include triangle(57vw 260px, #323232, up-right);
      }
    }
  }

  h1 {
    line-height: 1.1;
    margin-bottom: 10px;
    @include media(">=desktop") {
      margin-bottom: 20px;
    }
  }

  .display-4 {
    line-height: 1.2;
  }

  .container {
    position: relative;
  }

  .seconds {
    position: absolute;
    top: -6%;
    left: 16.75%;
    bottom: -3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media(">=tablet") {
      top: -18%;
      left: 38.75%;
    }

    .second {
      display: inline-block;
      z-index: 1;

      &-60 {
        max-width: 28px;
        margin-bottom: 18px;
        @include media(">=desktop") {
          margin-bottom: 61px;
        }
      }

      &-59 {
        max-width: 22px;
        margin-left: 18px;
        margin-bottom: 16px;
        @include media(">=desktop") {
          margin-left: 20px;
          margin-bottom: 71px;
        }
      }

      &-58 {
        max-width: 17px;
        margin-left: -10px;
        margin-bottom: 6px;
        @include media(">=desktop") {
          margin-left: 16px;
          margin-bottom: 54px;
        }
      }

      &-57 {
        max-width: 15px;
        margin-left: 27px;
        margin-bottom: 19px;
        @include media(">=desktop") {
          margin-left: 29px;
          margin-bottom: 69px;
        }
      }

      &-56 {
        margin-left: -8px;
        max-width: 11px;
        margin-bottom: 20px;
        @include media(">=desktop") {
          margin-left: 26px;
          margin-bottom: 40px;
        }
      }

      &-55 {
        max-width: 10px;
        margin-left: 4px;
        margin-bottom: 4px;
        @include media(">=desktop") {
          margin-left: 49px;
          margin-bottom: 7px;
        }
      }

      &-54 {
        max-width: 19px;
        margin-left: -40px;
        @include media(">=desktop") {
          margin-left: -34px;
          margin-bottom: 22px;
        }
      }

      &-53 {
        max-width: 17px;
        margin-left: 27px;
        margin-top: -11px;
        @include media(">=desktop") {
          margin-left: 87px;
          margin-bottom: 4[x];
        }
      }

      &-52 {
        max-width: 15px;
        margin-left: 5px;
        @include media(">=desktop") {
          margin-left: 50px;
        }
      }

      @include media(">=desktop") {
        max-width: 100%;
      }
    }
  }
}
