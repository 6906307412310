&-decor {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;

  > .container {
    position: relative;

    &:before,
    &:after {
      position: absolute;
      content: "";
      top: 0;
      width: 50vw;
      background: #323232;
      z-index: 1;
    }

    &:before {
      right: calc(100% - 15.5px);
      height: 235px;
    }

    &:after {
      left: calc(100% + 75px);
      height: 219px;
    }
  }

  .stat-picture {
    display: inline-block;
    transform: scaleY(-1);
  }
}
