&-content {
  font-size: 11px;
  line-height: 1.5;
  padding: 0 15px;

  @include media(">=tablet") {
    font-size: 18px;
    line-height: 1.7;
    padding: 0;
  }
}
