@import "../../vendor/bootstrap/bootstrap-variables";
@import "../../../../node_modules/bootstrap/scss/mixins/gradients";
@import "../../../../node_modules/bootstrap/scss/mixins/buttons";

&-black {
  @include button-variant(
    map-get($baseColors, "dark"),
    map-get($baseColors, "dark")
  );
}

&-outline-light {
  &:hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background: transparent;
    color: map-get($baseColors, "light");
    border-color: map-get($baseColors, "primary");
  }
}
