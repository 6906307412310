.section-contact {
  .h3 {
    margin-bottom: 65px;
  }

  .list-styled {
    margin-bottom: 20px;
    @include media(">=desktop") {
      margin-bottom: 45px;
    }
  }

  .map {
    height: 400px;
    margin: 0 -15px -25px;
    @include media ('>=tablet') {
      margin: 0 -40px -40px;
      height: 500px;
    }
    @include media(">=desktop") {
      width: calc(50vw - 15px);
      height: calc(100% + 160px);
      margin: -80px 0;
    }
    @include media(">=widescreen") {
      width: calc(50vw - 23px);
    }
  }
}
