.section-error {
  background: linear-gradient(
    to bottom,
    rgba(40, 39, 45, 1) 278px,
    rgba(16, 19, 18, 1) 278px
  );
  @include media(">=widescreen") {
    background: linear-gradient(
      to bottom,
      rgba(40, 39, 45, 1) 640px,
      rgba(16, 19, 18, 1) 640px
    );
  }

  .title {
    position: absolute;
    top: 280px;
    left: 0;
    right: 0;
    font-size: 44px;
    z-index: 1;
    @include media(">=tablet") {
      top: auto;
      bottom: 280px;
    }
    @include media(">=widescreen") {
      font-size: 60px;
    }
  }

  .close {
    position: absolute;
    top: 3.5%;
    right: 10%;
    z-index: 3;
  }

  .error-decoration {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: 375px;
    height: 400px;
    background: url("../images/way.svg") no-repeat center;
    background-size: contain;
    transform: translateX(-50%);
    overflow: hidden;
    @include media(">=tablet") {
      max-width: 700px;
      height: 600px;
    }
    @include media(">=widescreen") {
      max-width: 1000px;
      height: 650px;
      background-position: center 135%;
    }
  }

  .chest {
    position: absolute;
    top: 9%;
    left: 50%;
    width: 42%;
    transform: translateX(-62%);
    @include media(">=tablet") {
      width: auto;
      top: 5%;
    }
    @include media(">=widescreen") {
      top: 2%;
      width: 25%;
      transform: translateX(-61%);
    }
  }

  .steps {
    position: absolute;
    width: 86%;
    top: 40.5%;
    right: 41.5%;
    @include media(">=tablet") {
      top: 30%;
      width: 70%;
      right: 45%;
    }
  }

  .lock {
    position: absolute;
    width: 82.9%;
    top: -5.5%;
    right: -11.3%;
    @include media(">=widescreen") {
      width: 71.9%;
      top: -27.5%;
      right: -2.3%;
    }
  }

  .spider {
    position: absolute;
    top: -48%;
    left: -36%;
    @include media(">=tablet") {
      top: -68%;
    }
    @include media(">=widescreen") {
      top: -80%;
      width: 100%;
    }
  }
}
