.review-author {
  font-family: map-deep-get($reviewItem, "author", "font-family");
  font-size: 10px;
  color: map-deep-get($reviewItem, "author", "color");
  text-decoration: underline;

  @include media(">=tablet") {
    font-size: 16px;
  }
}
