.flex-column.h-100 {
  flex: 1 1 100%;
}

.wow {
  visibility: hidden;
}


hr {
  height: 0.1rem;
  background: #414140;
}

.list-view {
  width: 100%;
}