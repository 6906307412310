&-button-next,
&-button-prev {
  font-size: 0;
  background: none;
  width: auto;
  height: auto;
  color: currentColor;
  margin: 0;

  &:before {
    @extend [class^="icon-"];
    font-size: 6.6rem;
  }

  .modal-open & {
    z-index: 1;
  }
}

&-button-next {
  @extend .icon-arrow-right;
  right: 5rem;
  @include media(">=ultrawide") {
    right: 10.5rem;
  }
}

&-button-prev {
  @extend .icon-arrow-left;
  left: 5rem;
  @include media(">=ultrawide") {
    left: 10.5rem;
  }
}

&-button-next-sm,
&-button-prev-sm {
  &:before {
    font-size: 4rem;
  }
}

&-button-next-sm {
  @extend .icon-chevron-right;
}

&-button-prev-sm {
  @extend .icon-chevron-left;
}

&-button-disabled {
  opacity: 0;
  visibility: hidden;
}