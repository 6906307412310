$reviewItem: (
  "logo": (
    "border-width-xs": 2px,
    "border-width": 5px,
    "border-color": map-get($baseColors, "light")
  ),
  "content": (
    "background": map-get($baseColors, "light"),
    "border-radius": 10px,
    "border-radius-xs": 5px,
    "arrow-color": map-get($baseColors, "light")
  ),
  "author": (
    "font-family": map-get($fonts, "sans-serif"),
    "color": map-get($baseColors, "dark")
  )
)