.post {
  &-sm {
    font-size: 11px;
    @include media ('>=tablet') {
      font-size: 18px;
    }
    @include media(">=widescreen") {
      max-width: 570px;
      padding-left: 27px;
    }

    p {
      margin-bottom: 0;
      @include media ('>=tablet') {
        margin-bottom: 10px;
      }
    }
  }

  &-body {
    margin-bottom: 25px;
    @include media(">=widescreen") {
      margin-bottom: 95px;
    }

    p {
      text-indent: 0.5em;
    }
  }
}
