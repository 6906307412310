.close {
  @extend .icon-close;
  opacity: 1;
  font-size: 0;
  text-shadow: none;
  outline: none;

  &:before {
    @extend [class^="icon-"];
    font-size: 2.6rem;
  }

  &-sm {
    &:before {
      font-size: 2.2rem;
    }
  }

  &-light {
    color: map-get($baseColors, "light");
  }
}
