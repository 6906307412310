&-content {
  &-top,
  &-bottom {
    position: absolute;
    left: 0;
    right: 0;
    padding: 18px 28px;
  }

  &-top {
    top: 20px;

    @include media ('>=desktop') {
      top: 0;
    }
  }

  &-bottom {
    bottom: 0;
  }
}