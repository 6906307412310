&-booking {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  width: 285px;
  background: map-deep-get($questDetails, "booking", "background");
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  pointer-events: all;

  @include media ('>=desktop') {
      top: -42px;
      bottom: -42px;
  }

  &-body {
    @media screen and (min-height: 900px) {
      margin-bottom: 20px;
    }
  }

  h4 {
    margin-bottom: 10px;
  }

  .datepicker {
    margin-bottom: -10px;
  }

  .display-4 {
    font-size: 24px;
  }

  .btn-lg {
    padding-left: 56px;
    padding-right: 56px;
  }
}
