&-pure {
  &,
  &:focus {
    border-color: transparent;
    background: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    box-shadow: none;
    outline: none;
  }
}
