.goals {
  position: relative;
  padding: 5px 0 0;
  background-repeat: no-repeat;
  background-position: center 100%;
  background-size: 1440px auto;
  overflow: hidden;

  @include media(">=desktop") {
    min-height: 839px;
    padding: 15px 0 0;
  }

  &-title {
    line-height: 1.06;
    margin-bottom: 5px;
    @include media(">=desktop") {
      letter-spacing: -1.3px;
      margin-bottom: 18px;
    }

    & + .display-4 {
      max-width: 285px;
      @include media(">=desktop") {
        max-width: 100%;
      }
    }
  }

  &-picture {
    position: relative;
    max-width: 100%;
    height: auto;
    float: right;
    margin: -10px -95px 0 0;
    z-index: 2;
    @include media(">=tablet") {
      margin: -10px -180px 0 0;
    }
    @include media(">=desktop") {
      margin: 108px 0 0 -40px;
      max-width: 623px;
      float: none;
    }
  }

  .container {
    position: relative;
  }

  @import "goals-decor";
}
