&-collapse {
  flex-basis: 260px;
  flex-grow: 0;
  margin-left: auto;
  height: calc(100vh - 60px);
  overflow: auto;
  @include media(">=desktop") {
    height: auto;
    overflow: visible;
  }
}
