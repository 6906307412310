.book-modal {
  background: rgba(0,0,0,.9);

  .modal-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    min-height: calc(100vh - 120px);
    @include media(">=phone") {
      max-width: 100%;
    }
    @include media(">=widescreen") {
      min-height: calc(100vh - 88px);
      max-width: 1020px;
    }
  }

  .modal-header {
    display: flex;
    align-items: center;
    padding: 25px;
    margin-bottom: 15px;
    @include media(">=widescreen") {
      margin: 0 -80px;
    }

    .btn-link {
      @include v-align;
      padding: 0;
      text-decoration: none;
    }
  }

  .modal-content {
    flex: 1 1 100%;
    background: none;
    border: none;
    @include media(">=desktop") {
      padding: 0 80px;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    padding: 0 25px 25px;

    h4 {
      margin-bottom: 26px;
    }

    p {
      &:not(:empty) {
        margin-bottom: 10px;
      }
    }

    textarea.form-control {
      resize: none;
      min-height: 100px;
    }

    .form-group {
      @extend %clearfix;
      margin-bottom: 20px;
    }

    .input-group {
      flex: 1;
      border-bottom: 1px solid #636362;

      &:not(:last-child) {
        border-right: 1px solid #636362;
      }

      &:last-child {
        .input-group-label,
        .help-block-error,
        .form-control-pure {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }

    .input-group-label {
      & + .form-control-pure {
        margin-top: -7px;
      }
    }

    .btn-lg {
      @include media(">=desktop") {
        padding-left: 66px;
        padding-right: 66px;
      }
    }

    .form-control-pure {
      height: 26px;
    }

    hr {
      background: rgba(#ccc, 0.6);
      height: 1px;
      margin: 0 0 10px;
      border: none;
    }
  }

  .close {
    @include media(">=desktop") {
      margin: 17px 44px 0 0;
    }
  }

  .help-block-error {
    font-size: 10px;
    width: 100%;

    @include media ('>=desktop') {
      font-size: 18px;
    }
  }
}
