&-logo {
  position: relative;
  display: inline-block;
  width: 97px;
  height: 97px;
  border-radius: 200px;
  border: map-deep-get($reviewItem, "logo", "border-width-xs") solid
    map-deep-get($reviewItem, "logo", "border-color");

  @include media(">=tablet") {
    width: 196px;
    height: 196px;
    border-width: map-deep-get($reviewItem, "logo", "border-width");
  }

  &-sm {
    width: 68px;
    height: 68px;
    @include media(">=tablet") {
      width: 140px;
      height: 140px;
    }
  }

  &-lg {
    width: 122px;
    height: 122px;
    @include media(">=tablet") {
      width: 248px;
      height: 248px;
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }
}
