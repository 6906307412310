&-cover {
  position: relative;
  display: block;

  &:before {
    content: "";
    display: block;
    padding-top: 106.67%;
    font-size: 0;
    line-height: 0;
    @include media ('>=desktop') {
      padding-top: 60.827%;
    }
  }

  img,
  picture {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
