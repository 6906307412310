.location {
  .navbar & {
    margin-right: 10px;
  }

  &-lg {
    .modal-xl & {
      @include media(">=widescreen") {
        margin: auto;
      }
    }
  }

  .btn {
    @include v-align;
    border: 1px solid map-get($location, "border-color");
    background: none;
    font-size: 10px;

    @include media(">=desktop") {
      font-size: 11px;
      white-space: normal;
      padding: 5px;
    }

    @include media(">=widescreen") {
      height: 54px;
      padding: 8px 11px 0;
    }

    [class^="icon-"] {
      font-size: 16px;
      margin-right: 5px;

      @include media(">=desktop") {
        width: 100%;
        font-size: 22px;
        margin: 0 0 3px;
      }
    }
  }

  @import "location-list";
  @import "location-labirint";
}
