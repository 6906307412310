&-pagination {
  &-top.swiper-pagination-bullets {
    top: 10px;
    bottom: auto;
  }

  &.swiper-pagination-bullets {
    .swiper-pagination-bullet {
      width: 2rem;
      height: 0.5rem;
      border-radius: map-deep-get($swiper, "pagination", "border-radius");
      background: map-deep-get($swiper, "pagination", "background-xs");
      opacity: 1;

      @include media(">=desktop") {
        background: map-deep-get($swiper, "pagination", "background");
        opacity: 0.2;
      }

      &-active {
        background: map-deep-get($swiper, "pagination", "active-background");
        opacity: 1;
      }
    }
  }

  .modal-open & {
    z-index: 1;
  }
}
