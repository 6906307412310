.list {
  @extend %listreset;
  display: inline-flex;
  color: currentColor;

  > li {
    &:hover,
    &.active {
      > a {
        &:after {
          left: -2px;
          right: -2px;
        }
      }
    }
    > a {
      position: relative;
      text-decoration: none;

      &:after {
        @include animate(left right);
        position: absolute;
        content: "";
        bottom: -1px;
        left: 0;
        right: 0;
        height: 2px;
        background: #999;
      }
    }
  }

  > li:not(:last-child) {
    margin-right: 22px;
  }
}
