.intro-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: calc(100vw / 2.32);

  @include media ('>415px') {
    min-height: calc(100vw / 1.84);
  }

  @include media ('>=992px') {
    min-height: calc(100vw / 2.72);
  }

  @include media ('>=widescreen') {
    padding: 80px 0;
  }

  // &-lg {
  //   @include media ('>=widescreen') {
  //     min-height: 935px;
  //   }
  // }

  &-responsive {
    padding: 0;
    // padding: 14vw 0;

    // @include media ('>=desktop') {
    //   padding: 3vw 0;
    // }
  
    // @include media ('>=widescreen') {
    //   padding: 80px 0;
    // }
  
    // @include media ('>ultrawide') {
    //   padding: 8.7vw 0;
    // }
  }

  > .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 100%;
    position: relative;

    @include media ('>=992px') {
      display: block;
    }
  }

  &.intro-banner-responsive {
    > .container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @import "intro-banner-overlay";
  @import "intro-banner-content";
  @import "intro-banner-title";
  @import "intro-banner-cover";

  //modifiers import
  @import "intro-banner-hero";
}
