.quest-card {
  position: relative;
  @include media(">=desktop") {
    margin-bottom: 30px;
  }

  .swiper-slide & {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 0;
  }

  @import "quest-card-info";
  @import "quest-card-cover";
  @import "quest-card-content";
}
