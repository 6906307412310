.section-faq {
  padding: 40px 0;
  background: url('../images/faq-banner.jpg');
  background-size: cover;
  background-repeat: no-repeat;

  @include media('>=desktop') {
    padding: 43px 0 105px;
  }

  & + .section {
    padding-top: 44px;
  }
}
