// Note: any .css or .scss files included in the 'styles' directory
// will be correctly compiled during `gulp` and `gulp production`

//* Vendors *//
@import './node_modules/include-media/dist/include-media';


//* Abstracts modules *//
@import 'abstracts/main';

//* Base modules *//
@import 'base/main';

//* Components *//
@import 'components/main';

//* Layout modules *//
@import 'layout/main';

//* Promo modules *//
@import 'promo/main';
