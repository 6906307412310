&-content {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid map-get($navbar, "border-color-xs");
  padding: 10px 15px;

  @include media ('>=desktop') {
    border-bottom: none;
    padding: 0;
    width: auto;
  }

  .navbar-light & {
    background: map-deep-get($navbar, "light", "background");
  }
}
