.card {
  font-size: 20px;
  padding-top: 35px;
  padding-bottom: 35px;
  min-height: 178px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  &-lg {
    @include media(">=desktop") {
      min-height: 357px;
    }
  }

  &-title {
    margin-bottom: 40px;
    @include media("<tablet") {
      font-size: 23px;
      margin-bottom: 45px;
      // .intro-banner-responsive & {
      //   margin-bottom: 30px;
      // }
    }
    @include media("<phone") {
      // .intro-banner-responsive & {
      //   margin-bottom: 25px;
      // }
    }
  }

  &-description {
    margin-bottom: 40px;
    font-weight: 700;
    @include media(">=desktop") {
      margin-bottom: 83px;
    }
  }
}
