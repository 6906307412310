&-toolbar {
  background: map-deep-get($questDetails, "toolbar", "background");
  margin-left: -15px;
  margin-right: -15px;
  padding: 20px 25px;
  pointer-events: all;

  @include media(">=widescreen") {
    background: none;
    margin: 0;
  }

  @include media ('>=widescreen') {
    padding: 0;
  }

  .btn {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 500;
    padding: 10px 23px;
  }

  .details {
    font-size: 13px;
    font-weight: 700;

    &-item {
      &:not(:last-child) {
        margin-right: 50px;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .complexity {
    .icon {
      @include media(">=tablet") {
        font-size: 16px;
      }
    }
  }
}
