&-content {
  .intro-banner-hero & {
    padding-top: 35%;
    padding-left: 35%;
    position: relative;
    left: 0;
    @include media ('>=375px') {
      padding-top: 45%;
    }
    @include media(">=desktop") {
      padding-top: 200px;
      padding-left: 0;
      margin-left: -5px;
    }
    @include media(">=widescreen") {
      left: 50px;
    }
    @include media(">=ultrawide") {
      left: 65px;
    }
  }
}