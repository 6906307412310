&-hero {
  overflow: hidden;

  @include media(">=desktop") {
    overflow: visible;
  }
  @include media(">=widescreen") {
    padding: 0;
  }

  &:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: -82px;
    background: map-get($colors, "gray-1400");
    height: 100px;
    z-index: 1;
    transform: skewY(5deg);
    @include media(">=tablet") {
      bottom: -35px;
    }
    @include media(">=desktop") {
      display: none;
    }
  }

  .intro-banner-cover {
    @include media(">=desktop") {
      min-height: 935px;
    }

    &:before {
      @include media(">=desktop") {
        padding-top: 0;
      }
    }
  }

  .intro-banner-overlay {
    display: flex;

    &-middle {
      position: relative;
      max-width: 1440px;
      width: 100%;
      margin: 0 auto;
    }

    &-left,
    &-right {
      flex: 1;
    }

    &-left {
      background-image: url("../images/hero-left-bg.png");
      background-repeat: repeat-x;

      @include media(">=desktop") {
        background-size: auto 931.5px;
      }
    }

    &-right {
      background: map-get($colors, "gray-1000");
      height: 93%;
    }
  }

  .display-4 {
    margin-left: 5px;
  }

  .intro-banner-decor {
    position: absolute;
    bottom: -95px;
    right: 21px;
    z-index: 1;
  }

  .intro-banner-decor-1 {
    right: 29.5%;
    top: 100%;
    margin-top: 37px;
  }
}
