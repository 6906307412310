&-square {
  @extend .icon-check;
  @include animate(border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  width: 22px;
  height: 22px;
  margin-right: 15px;

  background: map-deep-get($checkbox, "square", "background");
  color: map-deep-get($checkbox, "square", "color");
  border: 1px solid map-deep-get($checkbox, "square", "border-color");

  &:before {
    @extend [class^="icon-"];
    @include animate(opacity);
    font-size: 20px;
    margin-top: -2px;
    margin-right: -1px;
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    border-color: map-deep-get($checkbox, "square", "hover-border-color");
  }

  input[type="checkbox"]:checked + & {
    border-color: map-deep-get($checkbox, "square", "hover-border-color");

    &:before {
      opacity: 1;
      visibility: visible;
    }
  }
}
