.question-item {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 43px;

  &-heading {
    width: 100%;
  }

  &-title {
    position: relative;
    display: inline-block;
    font-family: map-deep-get($questionItem, "title", "font-family");
    letter-spacing: 0.2px;
    font-size: 24px;
    margin: 0 0 40px 20px;
    padding: 13px 0;
    border-bottom: 1px solid
      map-deep-get($questionItem, "title", "border-color");

    @include media(">=widescreen") {
      margin: 0 0 20px 20px;
    }

    &:before,
    &:after {
      position: absolute;
      content: "";
      top: 100%;
      left: -17px;
    }

    &:before {
      @include triangle(
        17px 22px,
        transparent map-deep-get($questionItem, "title", "border-color"),
        down-right
      );
    }

    &:after {
      @include triangle(
        15.5px 20px,
        transparent map-get($baseColors, "light"),
        down-right
      );
      left: -16px;
      margin-top: -1px;
    }
  }

  &-body {
    font-size: 16px;
    line-height: 2;
    font-weight: 400;
    background: map-deep-get($questionItem, "body", "background");
    padding: 31.5px 40px;
    max-width: 768px;
    margin-right: 20px;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      text-decoration: underline;
    }
  }
}
