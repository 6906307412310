.list-group {
  @extend %listreset;
  margin-bottom: 22px;

  &-item {
    font-size: 16px;
    padding: 13px 0;
    border-bottom: 1px solid #646362;
  }

  .radio-list {
    margin-bottom: -5px;
  }
}