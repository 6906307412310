.languages {
  @extend %listreset;
  font-size: map-get($languages, "font-size-xs");
  white-space: nowrap;

  @include media ('>=tablet') {
    font-size: map-get($languages, "font-size");
  }

  .navbar & {
    margin-right: 4px;
    @include media(">=desktop") {
      margin: 10px 10px 0 20px;
    }
  }

  > li {
    display: inline-block;

    &.active {
      > a {
        color: map-get($languages, "active-color");
        background: map-get($languages, "active-background");
      }
    }

    > a {
      padding: 4px;
      color: map-get($languages, "color");
      text-decoration: none;
      @include media ('>=desktop') {
        padding: 2px 4px;
      }
    }
  }
}
