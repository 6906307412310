.promo-dark {
  display: block;
  height: auto;
  background-color: #000;
  position: relative;
  &.with-bg-image {
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url("../images/promo/labyrint_bg.svg");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      display: none;
      z-index: -1;
      @include media ('>tablet') {
        display: block;
      }
    }
  }
  .decor {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    @include media ('>tablet') {
      display: none;
    }
    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: -1;
    }
    &:before {
      top: -40%;
      background-image: url("../images/promo/labyrint_bg_mobile.svg");
    }
    &:after {
      top: 40%;
      background-image: url("../images/promo/labyrint_bg_mobile_bottom.svg");
    }
  }
}



.promo-header {
    padding: 20px 0;
    @include media ('>tablet') {
      padding: 0;
    }
  .inner-container {
    max-width: 1340px;
    padding: 0 30px;
    box-sizing: border-box;
    margin: 0 auto;
    position: relative;
  }
  .logo {
    width: 80px;
    display: block;
    position: relative;
    @include media ('>tablet') {
      transform: translateY(30px);
      width: 137px;
      margin: 0 auto;
    }
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
  .languages {
    position: absolute;
    top: 50%;
    right: 30px;
    a {
      color: #fff;
      .active & {
        color: #000;
      }
    }
  }
}

.promo-title {
  display: flex;
  flex-direction: column;
  text-align: center;
  text-transform: uppercase;
  padding: 0 40px;
  margin-bottom: 30px;
  @include media ('>tablet') {
    margin-bottom: 0;
  }
    span {
      display: inline-block;
      color: #fff;
    }
    .medium {
      font-weight: 500;
      margin-bottom: 5px;
      font-size: 26px;
      @include media ('>tablet') {
        font-size: 3.1vw;
      }
      @include media ('>largedesktop') {
        font-size: 50px;
      }
    }
    .with-bg {
      font-weight: 700;
      background-color: #FFCE00;
      font-size: 30px;
      padding: 0 5px;
      color: #000;
      margin-bottom: 5px;
      @include media ('>tablet') {
        font-size: 3.75vw;
        margin-bottom: 0;
      }
      @include media ('>largedesktop') {
        font-size: 60px;
      }
    }
    .thin {
      font-weight: 300;
      font-size: 28px;
      @include media ('>tablet') {
        font-size: 3.1vw;
      }
      @include media ('>largedesktop') {
        font-size: 50px;
      }
    }
}

.promo-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1330px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 auto 26px;
  @include media ('>tablet') {
    flex-direction: row;
    margin: 0 auto 40px;
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include media ('>tablet') {
      min-height: calc(100vh - (308px));
      order: 0 !important;
    }
    &:nth-child(1) {
        order: 1;
    }
    &:nth-child(2) {
      order: 0;
    }
    &:nth-child(3) {
      order: 2;
    }
    &-item {
      display: block;
      text-decoration: none;
      margin-bottom: 30px;
      @media (hover: hover) {
        a:hover {
          background: yellow;
        }
      }
      @include media ('>tablet') {
        margin-bottom: 0;
      }
      .image-holder {
        margin-bottom: 15px;
        position: relative;
        img {
          width: 100%;
          height: auto;
        }
      }
      .caption {
        font-size: 18px;
        font-weight: 500;
        line-height: 1;
        text-align: center;
        color: #fff;
        margin-bottom: 0;
        @include media ('>tablet') {
          font-size: 20px;
        }
      }

      &:first-child {
        @include media ('>tablet') {
          margin-bottom: 15px;
        }
      }

      &:last-child {
        @include media ('>tablet') {
          margin-top: 95px;
        }
        @include media ('>desktop') {
          margin-top: auto;
        }
      }
    }
  }
}

.pre-footer-text {
  max-width: 800px;
  margin: 0 auto 10px;
  padding: 0 20px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 1.1;
}

.promo-footer {
  .inner-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
    padding: 21px 20px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: calc(100% - 40px);
      height: 1px;
      left: 20px;
      top: 0;
      background-color: #C4C4C4;
    }
    @include media ('>tablet') {
      flex-wrap: nowrap;
      justify-content: center;
    }
  }
  .copyright {
    color: #fff;
    display: inline-block;
    width: 100%;
    margin-right: 40px;
    font-size: 20px;
    margin-bottom: 15px;
    @include media ('>tablet') {
      width: auto;
      font-size: 15px;
      margin-bottom: 0;
    }
  }
  .contact-block {
    display: flex;
    flex-direction: column;
    color: #fff;
    @include media ('>tablet') {
      flex-direction: row;
    }
    .contact-item {
      display: flex;
      align-items: center;
      order: 1;
      &:last-child {
        order: 0;
      }
      &:nth-child(1) {
        @include media ('>tablet') {
          margin: 0 5px;
        }
        a {
          @include media ('>tablet') {
            margin: 0 5px;
          }
        }
      }
      a, address {
        color: #fff;
        font-size: 16px;
        @include media ('>tablet') {
          font-size: 15px;
        }
      }
      span {
        display: none;
      }
      address {
        margin-bottom: 0;
      }
      .m-only {
        display: inline-block;
        margin-right: 3px;
        color: #FFCE00;
        @include media ('>tablet') {
          display: none;
        }
      }
      .divider {
        display: none;
        padding: 0 3px;
        @include media ('>tablet') {
          display: inline-block;
        }
      }
    }
  }
}

@media (hover: hover) {
  .column-item:hover {
    .image-holder {
      box-shadow: 0px 0px 60px 0px rgba(255, 206, 0, 0.35);
    }
  }
}
