.footer {
  border-top: 1px solid map-get($footer, "border-color");
  color: map-get($footer, "color");
  padding: 25px 0;
  font-size: 14px;

  @include media(">=desktop") {
    padding: 50px 0;
    font-size: 15px;
  }

  .list {
    white-space: nowrap;
    > li {
      margin-right: 0;

      > a {
        color: currentColor;

        &:not(:empty) {
          margin-left: 22px;
        }
      }
    }
  }
}
