.input-group {
  .input-group-prepend {
    margin: 0;
  }
  
  .input-group-text {
    border-right: none;
    margin: 0;
    min-width: 3.4rem;
  }

  .form-control {
    border-left: none;
    padding-left: 0;
    box-shadow: none;
  }

  &-label {
    font-size: 10px;
    width: 100%;
  }
}
