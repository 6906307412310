.stat {
  position: relative;
  display: flex;
  background-image: url("../images/summary-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0;
  color: map-get($colors, "gray");
  padding: 71px 0 36px;
  @include media(">=widescreen") {
    min-height: 625px;
    background-size: 1440px auto;
    padding: 351px 0 83px;
    align-items: flex-end;
  }

  &:before,
  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    content: "";
    width: calc(50vw - 720px);
  }

  &:before {
    left: 0;
    background-size: 120px 985px;
    background-image: url("../images/summary-bg-left.jpg");
  }

  &:after {
    right: 0;
    background-size: 120px 985px;
    background-image: url("../images/summary-bg-right.jpg");
  }

  &-items {
    margin-bottom: -25px;
  }

  &-item {
    font-size: 16px;
    margin-bottom: 25px;
    @include media(">=desktop") {
      margin-bottom: 20px;
    }

    img {
      margin-bottom: 10px;
      max-width: 56px;
      @include media(">=desktop") {
        max-width: 100%;
        margin-bottom: 13px;
      }
    }

    .h1 {
      font-size: 38px;
      margin-bottom: 0;
      @include media(">=desktop") {
        font-size: 60px;
      }
    }

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @import "stat-decor";
}
