.datepicker {
  font-size: 1.6rem;

  &-inline {
    width: 28.5rem;
    margin-left: -2rem;
    margin-right: -2rem;
  }

  table {
    margin: 0 auto;
    border-spacing: 10px;
    border-collapse: separate;
  }

  td,
  th {
    width: 3rem;
    height: 3rem;
    border-radius: 2rem;
    margin: 0.5rem;
  }

  .dow {
    font-size: 1.4rem;
    font-weight: 400;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
  }

  .datepicker-switch {
    font-size: 1.8rem;
    font-weight: 400;

    &:hover {
      background: none;
      color: map-deep-get($datepicker, "day", "header-active-color");
    }
  }

  .prev,
  .next {
    font-size: 0;

    &:hover {
      background: none;
      color: map-deep-get($datepicker, "day", "header-active-color");
    }

    &:before {
      @extend [class^="icon-"];
      font-size: 2.2rem;
    }
  }

  .prev {
    @extend .icon-chevron-left;
  }

  .next {
    @extend .icon-chevron-right;
  }

  table {
    tr {
      td {
        &.active {
          &:hover:hover,
          &.active {
            background: map-deep-get($datepicker, "day", "active-background");
            color: map-deep-get($datepicker, "day", "active-color");
          }
        }

        &.day {
          &.focusd,
          &:hover {
            background: map-deep-get($datepicker, "day", "active-background");
            color: map-deep-get($datepicker, "day", "active-color");
          }

          &:not(.crossed-out-day) {
            cursor: pointer;
          }
        }

        &.crossed-out-day {
          text-decoration: line-through;

          &:hover,
          &:hover:hover,
          &.focused {
            background: none;
            color: #999;
          }
        }

        span.focused,
        span.active.active,
        span.active:hover:hover,
        span:hover {
          background: map-deep-get($datepicker, "day", "active-background");
          color: map-deep-get($datepicker, "day", "active-color");
        }

        &.old {
          color: map-deep-get($datepicker, "day", "old-color");
        }
      }
    }
  }
}
