html {
  font-size: 62.5%;
}

body,
html {
  height: 100%;
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
}

#wrapper {
  flex: 1 1 100%;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

select::-ms-expand {
  display: none;
}
