&-content {
  position: absolute;
  font-size: 8px;
  line-height: 1.4;
  background: map-deep-get($reviewItem, "content", "background");
  color: map-get($colors, "gray-300");
  border-radius: map-deep-get($reviewItem, "content", "border-radius-xs");
  padding: 10px;
  max-width: 180px;
  @include media(">=tablet") {
    font-size: 16px;
    padding: 15px 20px;
    border-radius: map-deep-get($reviewItem, "content", "border-radius");
    max-width: 362px;
  }

  .review-item-left & {
    top: -25px;
    left: 71px;
    @include media(">=tablet") {
      top: -37px;
      left: 141px;
    }
  }

  .review-item-middle & {
    top: 10px;
    right: 145px;
    @include media(">=tablet") {
      top: 31px;
      right: 152px;
      width: 360px;
    }
  }

  .review-item-right & {
    top: 98px;
    right: 25px;
    max-width: 210px;
    @include media(">=tablet") {
      width: 417px;
      max-width: none;
      left: 54px;
      top: 191px;
    }
  }

  p {
    margin: 0;
  }
}

&-content-arrow {
  position: absolute;
  content: "";

  .review-item-left & {
    top: 100%;
    left: 13px;
    @include media(">=tablet") {
      left: 27px;
    }
  }

  .review-item-left & {
    @include triangle(8px 10px, transparent #fff, down-right);
    @include media(">=tablet") {
      @include triangle(16px 16px, transparent #fff, down-right);
    }
  }

  .review-item-middle & {
    left: 100%;
    bottom: 15px;
    @include media ('>=tablet') {
      bottom: 34px;
    }
  }

  .review-item-middle & {
    @include triangle(10px 10px, transparent #fff, down-right);
    @include media(">=tablet") {
      @include triangle(19px 17px, transparent #fff, down-right);
    }
  }

  .review-item-right & {
    @include triangle(10px 10px, transparent #fff, up-left);
    bottom: 100%;
    right: 78px;
    @include media(">=tablet") {
      @include triangle(19px 17px, transparent #fff, up-right);
      border-right: 0;
      left: 34.2%;
      right: auto;
    }
  }
}
