.partners {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 10px 0;
  margin-bottom: -35px;
  @include media(">=tablet") {
    padding-top: 40px;
    margin-bottom: -40px;
  }
}

.partner-item {
  @include animate(filter);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-bottom: 40px;
  padding: 0 20px;

  filter: grayscale(1);

  &:hover {
    filter: grayscale(0);
  }

  @include media(">=widescreen") {
    width: 20%;
  }
}
