.reviews {
  position: relative;
  padding-top: 35px;
  padding-bottom: 45px;

  @include media(">=tablet") {
    padding-top: 90px;
    padding-left: 4px;
    padding-bottom: 84px;
  }

  .review-decor {
    position: absolute;
    margin: 0;

    &-1 {
      top: -4.2%;
      left: 23.6%;
    }

    &-2 {
      top: 38.8%;
      left: 20%;
    }

    &-3 {
      top: -0.5%;
      right: 6.5%;
    }
  }

  .review-item {
    z-index: 1;
  }
}
