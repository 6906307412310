&-overlay {
  &,
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .intro-banner-responsive & {
    position: relative;
    width: auto;
    height: auto;
  }

  &-dark {
    &:after {
      content: "";
      background: map-get($introBanner, "overlay-background");
    }
  }
  .intro-banner-picture,
  picture
   {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    .intro-banner-responsive & {
      position: relative;
      height: auto;
      object-fit: unset;
    }
  }
}
