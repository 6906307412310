.review-item {
  @extend %clearfix;
  display: flex;
  position: relative;
  flex: 0 0 auto;

  // child components imports
  @import "review-item-logo";
  @import "review-item-content";
  @import "review-author";
}

// component modifiers

.review-item-left {
  max-width: 505px;
  @include media(">=desktop") {
    flex-basis: 505px;
  }
}

.review-item-middle {
  margin: 0;
  padding: 0 70px 0 0;
  @include media(">=widescreen") {
    margin: 143px 0 0px 107px;
    padding: 0;
  }
}

.review-item-right {
  margin: -72px 0 40px 0;
  padding-right: 12px;
  justify-content: flex-end;
  @include media(">=desktop") {
    margin: -47px 0 0 4px;
    padding: 0;
  }
}
