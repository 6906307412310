&-content {
  flex: 1 1 auto;
  border-radius: 0;
  
  &.bg-dark {
    background: map-get($baseColors, "dark") !important;
  }

  h4 {
    @include media ('<tablet') {
      font-size: 20px;
    }
  }
}
