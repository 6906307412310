&-title {
  font-size: 25px;
  margin-bottom: 0;

  @include media ('>=desktop') {
    font-size: 30px;
    margin-bottom: 15px;
  }

  & + .complexity {
    margin-left: 2px;
  }
}
