.checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  input[type="checkbox"]{
    position: absolute;
    top: -999px;
    right: -999px;
    opacity: 0;
    visibility: hidden;
  }

  @import 'checkbox-square';
  @import 'checkbox-label';
}