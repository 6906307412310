&-footer {
  padding: 0 25px;
  @include media ('>=tablet') {
    padding: 20px 60px 0;
  }
  @include media ('>=desktop') {
    padding: 60px 60px 0;
  }

  a {
    font-size: 11px;
    font-weight: 500;
    color: currentColor;
    text-decoration: underline;
    @include media ('>=tablet') {
      font-size: 16px;
    }
    @include media ('>=desktop') {
      font-size: 20px;
    }
  }
}