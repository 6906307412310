&-container {
  max-width: 1122px;
  width: 100%;

  &-fluid {
    max-width: 100%;
  }

  &-multirow {
    .swiper-slide {
      flex: 0 0 auto;
      height: auto;
    }
  }
}