.quest-details {
  position: relative;

  //child components import
  @import "quest-details-cover";
  @import "quest-details-toolbar";
  @import "quest-details-content";
  @import "quest-details-title";
  @import "quest-details-booking";

  .swiper-slide {
    min-height: calc(100vh - 88px);
    @media screen and (max-width: 1024px) and (max-height: 768px) {
      min-height: calc(100vh - 66px);
    }
    @media screen and (max-width: 768px) and (max-height: 1024px) {
      min-height: calc(100vh - 61px);
    }
    @media screen and (max-height: 800px) and (min-width: 1200px) {
      min-height: 760px;
    }
    @media only screen 
      and (min-device-width: 1366px) 
      and (max-device-width: 1366px) 
      and (orientation: landscape)
      and (-webkit-min-device-pixel-ratio: 2) 
    {
      min-height: calc(100vh - 250px);
    }

    .embed-responsive {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
