&-decor {
  position: absolute;
  top: 0;

  &-1 {
    @include media(">=tablet") {
      top: -9%;
      right: 23%;
    }
  }

  &-2 {
    top: 7.2%;
    right: 15.2%;
  }

  &-3 {
    top: 36.3%;
    left: 38%;
    max-width: 30px;
    @include media(">=tablet") {
      top: 38.3%;
      left: 9%;
    }
  }

  &-4 {
    top: 19.8%;
    right: 4.5%;
  }

  &-5 {
    top: 48.5%;
    left: 2.8%;
    max-width: 34px;
    @include media(">=tablet") {
      top: 57.5%;
      left: 6.8%;
    }
  }

  &-6 {
    top: 33.7%;
    right: 2.5%;
  }

  &-7 {
    top: 22.2%;
    right: 18.7%;
  }

  &-8 {
    top: 11%;
    right: 33%;
  }

  &-9 {
    top: 68%;
    right: 13.5%;
  }

  &-10 {
    top: 40.7%;
    left: 13.7%;
    max-width: 23px;
    @include media(">=tablet") {
      top: 44.7%;
      left: 9.7%;
    }
  }

  &-11 {
    top: 40.5%;
    left: 30%;
  }

  &-12 {
    top: 69.8%;
    left: 17.5%;
    max-width: 45px;
    @include media(">=tablet") {
      top: 72.8%;
      left: 17.5%;
    }
  }

  &-13 {
    top: 60.1%;
    right: 22.8%;
  }

  &-14 {
    top: 29.6%;
    right: 27.3%;
  }

  &-15 {
    top: 12.6%;
    right: 18.5%;
    max-width: 42px;
    @include media(">=tablet") {
      top: 37.6%;
      right: 44.5%;
    }
  }

  &-16 {
    top: 21.6%;
    right: 4%;
    max-width: 12px;
    @include media(">=tablet") {
      top: 20.6%;
      right: 42%;
    }
  }

  &-17 {
    top: 59.6%;
    right: 6%;
  }

  &-18 {
    top: 45.9%;
    left: 20.4%;
    max-width: 62px;
    @include media(">=tablet") {
      top: 48.9%;
      left: 17.4%;
    }
  }

  &-19 {
    top: 65%;
    left: 6.1%;
    max-width: 14px;
    @include media(">=tablet") {
      top: 70%;
      left: 12.1%;
    }
  }

  &-20 {
    top: 63.6%;
    left: -2.2%;
  }

  @include media(">=tablet") {
    max-width: 100%;
  }
}
