&-labirint {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 32px;
  font-weight: 600;
  overflow: hidden;
  width: 250px;
  height: 250px;
  margin-left: auto;
  margin-right: auto;
  @include media(">=desktop") {
    width: 400px;
    height: 400px;
  }
  @include media(">=widescreen") {
    width: 530px;
    height: 530px;
  }

  &:hover {
    svg {
      fill: #232322;
      visibility: visible;
      opacity: 1;
    }
  }

  svg,
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:after {
    content: "";
    background: radial-gradient(transparent -30%, map-get($baseColors, "dark"));
  }

  svg {
    @include animate(opacity);
    fill: #232322;
    opacity: 0;
    visibility: hidden;
    transform: scale(1.2);
  }

  .title {
    color: currentColor;
    z-index: 1;
  }
}
