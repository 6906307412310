.article {
  font-size: 11px;
  @include media(">=tablet") {
    font-size: 14px;
  }
  @include media(">=desktop") {
    display: flex;
    flex-direction: column;
    font-size: 18px;
  }

  > [class^="col-"] {
    padding-left: 0;
    padding-right: 0;

    @include media(">=desktop") {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &-cover {
    position: relative;
    display: block;
    color: map-deep-get($article, "cover", "color");

    &:before {
      content: "";
      display: block;
      padding-top: 48%;
      font-size: 0;
      line-height: 0;

      @include media(">=desktop") {
        padding-top: 91.7%;
      }
      @include media(">=widescreen") {
        padding-top: 61.08%;
      }
    }

    > img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-subtitle {
    font-size: 18px;
    line-height: 1.2;
    margin: 0;
    @include media(">=tablet") {
      font-size: 22px;
    }
    @include media(">=desktop") {
      font-size: 32px;
      line-height: 1.3;
      letter-spacing: -0.2px;
      margin-bottom: 6px;
    }
  }

  &-info {
    position: absolute;
    left: 0;
    bottom: -1px;
    right: 0;
    background: map-deep-get($article, "info", "background");
    padding: 6px 25px;
  }

  &-content {
    padding: 0 25px;
    @include media(">=desktop") {
      padding: 0;
      line-height: 1.7;
      letter-spacing: 0.18px;
    }
  }

  time {
    font-size: 9px;
    @include media(">=tablet") {
      font-size: 16px;
    }
    @include media(">=desktop") {
      font-size: 18px;
    }
  }

  iframe {
    max-width: 100%;
  }
}
