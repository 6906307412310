@import "../../../node_modules/bootstrap/scss/mixins";

@include text-emphasis-variant(".text-standart", #28a745);
@include text-emphasis-variant(".text-premium", lighten(#0d5797, 30%));
@include text-emphasis-variant(".text-special", #9c27b0);
@include text-emphasis-variant(".text-holiday", #ef6c00);

.font-weight-medium {
  font-weight: 500;
}

.font-weight-semibold {
  font-weight: 600;
}

.lead {
  line-height: 1.9;
}

h1 {
  @include media("<desktop") {
    font-size: 35px;
  }
  @include media("<tablet") {
    font-size: 21px;
  }
}

h3 {
  @include media("<desktop") {
    font-size: 30px;
  }
  @include media("<tablet") {
    font-size: 21px;
    letter-spacing: -1px;
  }
}

.display-4 {
  @include media("<tablet") {
    font-size: 13px;
  }
}

.text-md {
  font-size: 14px;
}

.text-overflow {
  @extend .ellipsis;
}
