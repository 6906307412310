&-dialog {
  &.modal-xl {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    min-height: 100%;
    margin: 0;

    @include media(">=desktop") {
      max-width: calc(100% + 15px);
      width: calc(100% + 15px);
      margin: 0 -15px 0 0;
    }

    .close {
      @include media(">=desktop") {
        margin-top: 20px;
        margin-right: 20px;
      }
    }
  }
}
