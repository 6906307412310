&-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 2;
  pointer-events: none;

  @include media(">=widescreen") {
    padding: 42px 0;
  }

  &:after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: map-deep-get($questDetails, "overlay", "background");
    pointer-events: none;
  }

  > .container {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
    flex: 1 1 auto;
    max-width: 100%;

    @include media ('>=widescreen') {
      max-width: 1270px;
    }

    > .row {
      flex: 1 1 auto;
    }
  }
}
