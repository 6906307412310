.section {
  padding: 25px 0;
  background-size: cover;
  background-repeat: no-repeat;
  @include media(">=tablet") {
    padding: 40px 0;
  }
  @include media(">=desktop") {
    padding: 80px 0;
  }

  &-sm {
    @include media(">=tablet") {
      padding: 55px 0;
    }

    p {
      margin-bottom: 17px;
      @include media ('>=tablet') {
        margin-bottom: 30px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-fs {
    min-height: 100vh;
  }

  //section child components
  @import "section-heading";
  @import "section-subtitle";
  @import "section-content";
  @import "section-footer";
}

// component modifiers
@import "section-contact";
@import "section-contact";
@import "section-reviews";
@import "section-faq";
@import "section-book";
@import "section-error";
